<template>
  <section>
    <div class="ftr has-background-black has-text-white is-full-width">
      <div class="columns">
        <div class="column col-first is-2 pl-0 ml-6">
          <b-image :src="require('@/assets/img/home/logo.png')"></b-image>
        </div>
        <div class="column is-3 has-text-centered right-border-primary">
          DANE KONTAKTOWE
        </div>
        <div class="column is-3 left-border-primary pl-6">
          <div class="pb-2">SYSTEM</div>
          <div class="py-2">WSPÓŁPRACA</div>
          <div class="py-2">PARTNERZY</div>
          <div class="py-2">KONTAKT</div>
        </div>
        <div class="column is-3 left-border-primary pl-6">
          <div class="pb-2">DOKUMENTY</div>
          <div class="py-2"><a class="is-primary" href="docs/regulamin.pdf">Regulamin</a></div>
          <div class="py-2"><a class="is-primary" href="docs/polityka_prywatnosci.pdf">Polityka prywatności</a></div>
          <div class="py-2"><a class="is-primary" href="docs/formularz_odstapienia.pdf">Warunki Odstąpienia</a></div>
        </div>
      </div>
      <div class="columns is-centered mt-6">
        Developed by

        <a class="ml-1" href="https://netbuild.pl/"> Netbuild</a>
      </div>
    </div>
    <div class="columns banner is-12">
      <b-image
        class="center"
        :src="require('@/assets/img/banners/b1.png')"
      ></b-image>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ftr {
  left: -0.7rem;
  position: relative;
  width: 100vw;
  height: 300px;
}

.col-first {
  position: relative;
  left: 1rem;
}

.banner {
  position: relative;
  background-color: #fff;
  height: 150px;
  width: 100vw;
  justify-content: center;
  figure {
    position: absolute;
    text-align: center;
  }
}
</style>